.screen-container {
  flex: 1;
  display: flex;
  background-color: white;
  overflow: hidden;
  padding: 40px;
}

/* Icon Button */

.icon-button {
  height: 50px;
  width: 50px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon-button:hover {
  background-color: #f2f2f2;
}

/* Button */

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 5px 0px;
  cursor: pointer;
}

/* Option Item */

.opt-item {
  margin-bottom: 0px;
}