.top-one {
  grid-area: top-one;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.top-two {
  grid-area: top-two;
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
}

.stats {
  grid-area: stats;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 5;
}

.circle-one {
  grid-area: circle-one;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.circle-two {
  grid-area: circle-two;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

.chart {
  grid-area: chart;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 5;
}

.empty-space {
  grid-area: empty;
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 5;
  grid-row-end: 6;
}

.stats-grid {
  height: 0vh;
  max-width: 100vw;
  padding: 50px;
  box-sizing: content-box;

  display: grid;
  grid-template-columns: repeat(1fr, 4);
  grid-template-rows: repeat(1fr, 4);

  grid-gap: 30px;
}

.stats-grid > div {
  padding: 20px;
  border-radius: 8px;
  box-sizing: border;
}

.stats-grid div h1 {
  margin: 0px;
  font-size: 50px;
}