html, body {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  overflow: hidden;

  margin: 0px;
  padding: 0px;
}

* {
  font-family: 'Poppins', sans-serif !important;
  box-sizing: border-box;
  outline: none;
}

pre {
  font-family: 'Roboto Mono', monospace !important;
}

.recharts-wrapper  {
  margin : auto;
}