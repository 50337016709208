.backdrop {
  transition: 0.2s ease-in-out;
}

.backdrop-hide {
  visibility: hidden;
  opacity: 0;
}

.backdrop-open {
  visibility: visible;
  opacity: 1;
}

.backdrop-surface {
  transition: opacity 0.3s, transform 0.2s ease-in-out;
}

.backdrop-hide > .backdrop-surface {
  transform: translateX(200px);
  opacity: 0;
}

.backdrop-open > .backdrop-surface {
  transform: translateX(0px);
  opacity: 1;
}

.backdrop-surface > div > div {
  display: flex;
  flex-direction: column;
}